<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="advanced_search"
              >
                <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-card
                v-show="advanced_search_modal"
        >
          <b-form
                  @submit.prevent="searchByCondition"
                  @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="销售订单号:"
                        label-for="order_no"
                        label-size="sm"
                >
                  <b-form-input
                          v-model="orderNo"
                          class="d-inline-block mr-1"
                          size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="出库单编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="outboundNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="商品69码:"
                        label-for="productCode"
                        label-size="sm"
                >
                  <b-form-input
                          v-model="productCode"
                          class="d-inline-block mr-1"
                          size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="productCode"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="productName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="创建人:"
                    label-for="creator_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="creator_name"
                      size="sm"
                      v-model="creatorName"
                      @click="showSingleModal('创建人')"
                      readonly
                      placeholder="点击搜索创建人"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="客户单位:"
                    label-for="channel"
                    label-size="sm"
                >
                  <b-form-input
                      id="channel"
                      size="sm"
                      v-model="channelName"
                      @click="showSingleModal('客户单位')"
                      readonly
                      placeholder="点击搜索客户单位"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="状态:"
                        label-for="status_id"
                        label-size="sm"
                >
                  <v-select
                          id="status_id"
                          :options="getCodeOptions('sale_outbound_status')"
                          :clearable="true"
                          v-model = "statusId"
                          class="select-size-sm"
                          placeholder="选择出库状态"
                  />
                </b-form-group>
              </b-col>
              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(outbound_id)="data">

          <!--          <b-link-->
          <!--              :to="{ name: 'apps-brand-edit', query: { id: data.item.id } }"-->
          <!--              class="font-weight-bold d-block text-nowrap"-->
          <!--          >-->
          #{{ data.item.outbound_id }}
          <!--          </b-link>-->
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields">
                <template #cell(ext)="data">
                  {{ data.item.ext.code }}
                </template>

                <template #cell(name)="data">
                  [#{{ data.item.product_id }}]{{ data.item.name }}
                  <feather-icon
                      icon="CpuIcon"
                      size="21"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="success"
                      @click="function () {
                      stockOccupied.warehouse_id=data.item.ourwarehouse_id
                      stockOccupied.product_id=data.item.product_id
                      stockOccupied.stock_id=data.item.stock_id
                      $refs['modal-stock-lock'].show()
                      $forceUpdate()
                      }"
                  />
                </template>

              </b-table>
            </div>
          </b-card>
        </template>

        <!-- Columns -->
        <template #cell(outbound_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
            [#{{ data.item.id }}]{{data.item.outbound_no}}
        </template>

        <!-- Column: Type -->
        <template #cell(salesoutbound_type)="data">
          {{ getCodeLabel('salesoutbound_type', data.item.salesoutbound_type) }}
        </template>

        <template #cell(channel_name)="data">
          {{ data.item.ext.channelName }}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('sale_outbound_status', data.item.status)}`"
          >
            {{ getCodeLabel('sale_outbound_status', data.item.status) }}
          </b-badge>
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel("user", data.item.creator) }}
        </template>

        <template #cell(outbound_time)="data">
          {{ toTime(data.item.outbound_time) }}
        </template>

        <template #cell(outbound_creator)="data">
          {{ getCodeLabel("user", data.item.outbound_creator) }}
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-salesoutbound-edit', query: { id: data.item.id }}"
                             v-if="data.item.status === 0">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑出库单</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-salesoutbound-edit', query: { id: data.item.id }}"
                             v-if="data.item.status === 1 || data.item.status === 2">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">查看出库单</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="submit(data.item)" @click="submitWare(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">提交仓库</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="detailed(data.item)" v-b-modal.modal-select-stock-inbound-item>
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">入库明细</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="returnGoods(data.item)"
                             :to="{ name: 'apps-salesreturnbound-edit',query:{outbound_id:data.item.outbound_id}}">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">生成退货单</span>
            </b-dropdown-item>


          </b-dropdown>
        </template>

      </b-table>

      <b-modal
          id="modal-select-stock-inbound-item"
          ok-only
          centered
          size="lg"
      >
        <stock-inbound-item-list
            ref="myContactSelects">
        </stock-inbound-item-list>
      </b-modal>


      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >
          <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
          <user-select
              ref="userSelect" :userDepartment="userDepartment" v-if="modalName === '创建人' "
          >
          </user-select>

          <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName === '客户单位' "
          >
          </company-select>

        </b-modal>

        <b-modal
            id="modal-stock-lock"
            ref="modal-stock-lock"
            no-close-on-backdrop
            cancel-title="取消"
            centered
            size="xl"
            title="锁库单据"
        >
          <stock-occupied-list
              :warehouse_id="stockOccupied.warehouse_id"
              :product_id="stockOccupied.product_id"
              :stock_id="stockOccupied.stock_id"
          >

          </stock-occupied-list>
        </b-modal>

      </div>
    </b-card>

    <!--<b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
    >

      &lt;!&ndash;<company-select
              ref="companySelect" :companyType="companyType" v-if="modalName === '供应商' "
      >
      </company-select>&ndash;&gt;

      &lt;!&ndash; :userDepartment="userDepartment"  判断负责人所属部门&ndash;&gt;
      <user-select
              ref="userSelect" v-if="modalName === '创建人' "
      >
      </user-select>


    </b-modal>-->

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import salesoutboundUseList from './salesoutboundUseList'
import salesoutboundStore from './salesoutboundStore'
import codeStore from "@/views/apps/code/codeStore";
import StockInboundItemList from "@/views/apps/stockinbounditem/StockInboundItemList";
import axios from "@/libs/axios";
import {useToast} from "vue-toastification/composition"
import CompanySelect from "../company/CompanySelect"
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import Ripple from "vue-ripple-directive";
import StockOccupiedList from "@/views/apps/stockoccupied/StockOccupiedList.vue";

export default {
  components: {
    StockOccupiedList,
    StockInboundItemList,
    CompanySelect,
    UserSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    useToast,
  },
  data() {
    return {
      stockOccupied:{},
      fields: [
        { key: 'ext.code', label: '商品69码' },
        { key: 'name', label: '名称' },
        { key: 'ourwarehouse_name', label: '所在仓库' },
        { key: 'ext.productCostTax', label: '含税售价' },
        { key: 'qty', label: '计划出库数量' },
        { key: 'trueqty', label: '实际出库数量' },
        { key: 'receiveqty', label: '对方接收数量' },
      ],
      advanced_search_modal:false,
      companyType: '',
      modalName: '',
      userDepartment: "sales",
      creatorName:'',
      channelName:'',
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('salesoutbound/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
   /* showSingleModal(modalName) {
      if (modalName === '供应商') {
        this.companyType = 'Supplier'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()
    },*/

   /* onSelectSingle() {
      let data = {}
      switch (this.modalName) {
        case '创建人':{
          data = this.$refs.userSelect.getSelected()
          if(data.length > 0){
            this.creatorName = data[0].full_name;
            this.creatorId = data[0].user_id;
          }
          break;
        }
        default: {
          //statements;
          break
        }
      }
    },*/

    searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.productCode = '';
      this.orderNo = '';
      this.statusId = '';
      this.creatorName = '';
      this.creatorId = '';
      this.channelId = '';
      this.productName = '';
      this.channelName = '';
      this.outboundNo = '';
      this.refetchData();
    },
    showSingleModal(modalName) {
      this.modalName = modalName
      if (modalName === '客户单位') {
        this.companyType = 'OffChannelAndChannel'
      }
      this.$refs['singleModal'].show()
    },
    onSelectSingle() {
      let data = {}
      switch (this.modalName) {
        case '创建人': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.creatorName = data[0].full_name;
            this.creatorId = data[0].user_id;
          }
          break;
        }
        case '客户单位': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.channelName = data[0].company_name;
            this.channelId = data[0].company_id;
            this.$forceUpdate()
          }
          break
        }
        default: {
          //statements;
          break
        }
      }
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('salesoutbound')) store.registerModule('salesoutbound', salesoutboundStore)
    if (!store.hasModule('code')) store.registerModule('code', codeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesoutbound')) store.unregisterModule('salesoutbound')
      if (store.hasModule('code')) store.unregisterModule('code')
    })

    const change = function (val) {
      if (val.status === 0 || val.status === 1 || val.status === 2) return true
      else return false
    }

    const submit = function (val) {
      if (val.status === 0) return true
      else return false
    }

    const detailed = function (val) {
      if (val.status === 2) return true
      else return false
    }

    const returnGoods = function (val) {
      if (val.status === 3) return true
      else return false
    }


    const submitWare = function (val) {
      var res = confirm("是否提交仓库？")
      if (res) {
        store
            .dispatch('salesoutbound/submitwarehouse',
                {
                  id: val.outbound_id
                }
            )
            .then(response => {
              if (response.data.code===1){
                toast.error(response.data.data)
              }else {
                toast.success("操作成功!")
                refetchData()
              }

            })
            .catch((e) => {
              toast.error("操作失败!")
              refetchData()
            })
      }
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      productCode, orderNo, statusId,productName,creatorId,outboundNo,channelId,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = salesoutboundUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      productCode, orderNo, statusId,productName,creatorId,outboundNo,channelId,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      returnGoods,
      toTime, toDate,
      // UI
      change,
      submit,
      detailed,
      submitWare,
      advanced_search,
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
